
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' },
    ]

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("CustomerCreate");
    const canUpdate = checkPermissions("CustomerUpdate");
    const canDelete = checkPermissions("CustomerDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '客戶管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      expand: true,
      printConfig: { sheetName: '客戶管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '客戶管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'Number',
          title: '客戶代號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          slots: { default: 'link', }
        },
        {
          field: 'Name',
          title: '名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'Email',
          title: 'Email',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'Address',
          title: '地址',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('customer/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('customer/query') : undefined,
        save: model ? (params) => model.dispatch('customer/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '客戶代號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Name',
          title: '簡稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'EnglishName',
          title: '英文名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Address',
          title: '地址',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. 41 Lesmill Rd.Toronto, ON Canada M3B 2T3', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Email',
          title: 'Email',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Phone',
          title: '電話號碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Fax',
          title: '傳真號碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ATTN',
          title: 'ATTN',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. MR.Yusuf A Travadi', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PaymentTerm',
          title: 'Payment',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. 60 Days After ETD', clearable: true }, attrs: { type: 'text' } }
        },        
        {
          field: 'LeadTime',
          title: 'LeadTime',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. 60 Days', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'TermDelivery',
          title: 'TermDelivery',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. FOB', clearable: true }, attrs: { type: 'text' } }
        },        
        {
          field: 'BankInfo',
          title: '銀行資訊',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.BankInfo).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Consignee',
          title: '收貨人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. Veyer LLC', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Shipper',
          title: '託運人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. IRON MAN PACKING IND CO., LTD', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'TaxType',
          title: '稅別',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. S', clearable: true }, attrs: { type: 'text' } }
        },        
        {
          field: 'Country',
          title: '國別',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. USA', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Source',
          title: '客戶來源',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.CustomerSource).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'CustomerSource',
          title: '說明',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ShippingMark',
          title: 'Shipping Mark',
          span: 24,
          itemRender: { name: '$textarea', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'DisplayCBM',
          title: '顯示CBM',
          span: 12,
          itemRender: { name: '$select', props: { readonly: false, disabled: false }, options: [
            { value: true, label: '是' },
            { value: false, label: '否' },
          ]}
        },
        {
          field: 'DisplaySQM',
          title: '顯示SQM',
          span: 12,
          itemRender: { name: '$select', props: { readonly: false, disabled: false }, options: [
            { value: true, label: '是' },
            { value: false, label: '否' },
          ]}
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        },
      ],
      rules: {
        Number: [{ type: 'string', required: true }],
        Name: [{ type: 'string', required: true }],
        Email: [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        Address: [{ type: 'string', required: true }],
        Phone: [
          {
            type: "string",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        { field: "Type", title: "文件類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentType).find(e => e.Value === cellValue)?.Name : undefined },
        
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("customer/find", value), // eslint-disable-line
        query: (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const initData = (row: any, callback: any) => {
      callback();
    }

    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
    })
    const TF = [{ label: "是", value: true }, { label: "否", value: false }]
    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      canCreate,
      linkVXEModal,
      linkModal,
      TF
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
