<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">客戶管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing && canCreate" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入客戶" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initData"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <template #link="{ row }">
            <div @click="linkModal.show(row)" class="inline-block bg-transparent text-blue-500 hover:underline cursor-pointer">
              <p>{{row.Number}}</p>
            </div>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #content="{ row }">
            <div class="m-5">
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <tbody>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶代號</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Number }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">名稱</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Name }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">英文名稱</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.EnglishName }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">地址</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Address }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Email</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Email }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">電話號碼</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Phone }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">傳真號碼</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Fax }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">ATTN</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ATTN }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Payment</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.PaymentTerm }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">LeadTime</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.LeadTime }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">國別</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Country }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Remark }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal ref="linkVXEModal" v-model="linkModal.visible" fullscreen title="顯示客戶資料" show-zoom="true">
      <div class="m-5">
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <tbody>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶代號</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Number }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">名稱</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Name }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">英文名稱</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.EnglishName }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">地址</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Address }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Email</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Email }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">電話號碼</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Phone }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">傳真號碼</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Fax }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">ATTN</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ATTN }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Payment</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.PaymentTerm }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">LeadTime</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.LeadTime }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">國別</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Country }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Remark }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
    </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' },
    ]

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("CustomerCreate");
    const canUpdate = checkPermissions("CustomerUpdate");
    const canDelete = checkPermissions("CustomerDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '客戶管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      expand: true,
      printConfig: { sheetName: '客戶管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '客戶管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'Number',
          title: '客戶代號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          slots: { default: 'link', }
        },
        {
          field: 'Name',
          title: '名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'Email',
          title: 'Email',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'Address',
          title: '地址',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('customer/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('customer/query') : undefined,
        save: model ? (params) => model.dispatch('customer/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '客戶代號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Name',
          title: '簡稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'EnglishName',
          title: '英文名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Address',
          title: '地址',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. 41 Lesmill Rd.Toronto, ON Canada M3B 2T3', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Email',
          title: 'Email',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Phone',
          title: '電話號碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Fax',
          title: '傳真號碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ATTN',
          title: 'ATTN',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. MR.Yusuf A Travadi', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PaymentTerm',
          title: 'Payment',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. 60 Days After ETD', clearable: true }, attrs: { type: 'text' } }
        },        
        {
          field: 'LeadTime',
          title: 'LeadTime',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. 60 Days', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'TermDelivery',
          title: 'TermDelivery',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. FOB', clearable: true }, attrs: { type: 'text' } }
        },        
        {
          field: 'BankInfo',
          title: '銀行資訊',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.BankInfo).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Consignee',
          title: '收貨人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. Veyer LLC', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Shipper',
          title: '託運人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. IRON MAN PACKING IND CO., LTD', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'TaxType',
          title: '稅別',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. S', clearable: true }, attrs: { type: 'text' } }
        },        
        {
          field: 'Country',
          title: '國別',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: 'ex. USA', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Source',
          title: '客戶來源',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.CustomerSource).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'CustomerSource',
          title: '說明',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ShippingMark',
          title: 'Shipping Mark',
          span: 24,
          itemRender: { name: '$textarea', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'DisplayCBM',
          title: '顯示CBM',
          span: 12,
          itemRender: { name: '$select', props: { readonly: false, disabled: false }, options: [
            { value: true, label: '是' },
            { value: false, label: '否' },
          ]}
        },
        {
          field: 'DisplaySQM',
          title: '顯示SQM',
          span: 12,
          itemRender: { name: '$select', props: { readonly: false, disabled: false }, options: [
            { value: true, label: '是' },
            { value: false, label: '否' },
          ]}
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        },
      ],
      rules: {
        Number: [{ type: 'string', required: true }],
        Name: [{ type: 'string', required: true }],
        Email: [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        Address: [{ type: 'string', required: true }],
        Phone: [
          {
            type: "string",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        { field: "Type", title: "文件類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentType).find(e => e.Value === cellValue)?.Name : undefined },
        
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("customer/find", value), // eslint-disable-line
        query: (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const initData = (row: any, callback: any) => {
      callback();
    }

    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
    })
    const TF = [{ label: "是", value: true }, { label: "否", value: false }]
    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      canCreate,
      linkVXEModal,
      linkModal,
      TF
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
